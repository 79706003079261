// from dotenv

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    API_SERVER: process.env.REACT_APP_API_SERVER,

    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,

    stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    stripePricingTableId: process.env.REACT_APP_STRIPE_PRICING_TABLE_ID,

    appUrl: 'https://app.flashkardai.com',
    landingUrl: 'https://flashkardai.com',
    termsOfServiceUrl: 'https://app.flashkardai.com/policies/terms',
    privacyPolicyUrl: 'https://app.flashkardai.com/policies/privacy',
    supportUrl: 'mailto:hello@flashkardai.com'
};

export default config;
