import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import configData from '../../../../config';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Avatar,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Switch,
    Typography,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    Stack,
    Select,
    MenuItem
} from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItemButton';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import axiosHttp from '../../../../utils/axios'

// project imports
import MainCard from '../../../../ui-component/cards/MainCard';
import Transitions from '../../../../ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import { LOGOUT } from './../../../../store/actions';
import useScriptRef from '../../../../hooks/useScriptRef';
import AnimateButton from '../../../../ui-component/extended/AnimateButton';
import { ACCOUNT_INITIALIZE } from './../../../../store/actions';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// assets
import { IconLogout, IconSearch, IconUser, IconSettings, IconHeart, IconMessageHeart, IconArrowBigUpLines, IconX, IconDashboard, IconPointFilled, IconHelp, IconUsers } from '@tabler/icons-react';
import User1 from './../../../../assets/images/users/user-round.svg';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import config from '../../../../config';

// style const
const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        maxWidth: '350px',
        minWidth: '300px',
        backgroundColor: theme.palette.primary.light,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%'
        }
    },
    headerAvatar: {
        cursor: 'pointer',
        ...theme.typography.mediumAvatar,
        margin: '8px 0 8px 8px !important'
    },
    profileChip: {
        height: '48px',
        alignItems: 'center',
        borderRadius: '27px',
        transition: 'all .2s ease-in-out',
        borderColor: 'transparent', //theme.palette.secondary.dark,
        backgroundColor: 'transparent', //theme.palette.secondary.dark,
        // '&[aria-controls="menu-list-grow"], &:hover': {
        //     borderColor: theme.palette.secondary.light,
        //     background: theme.palette.secondary.light + '!important',
        //     color: theme.palette.secondary.dark,
        //     '& svg': {
        //         stroke: theme.palette.secondary.dark
        //     }
        // }
        '& svg': { stroke: theme.palette.common.black }
    },
    profileLabel: {
        lineHeight: 0,
        padding: '12px',
    },
    listItem: {
        marginTop: '5px',
    },
    ListItemButton: {
        marginTop: '5px',
        '&:hover': {
            color: theme.palette.common.black
        },
        '&:hover $Icon': {
            color: theme.palette.common.black,
        }
    },
    Icon: {
        color: theme.palette.common.black
    },
    cardContent: {
        padding: '16px !important'
    },
    card: {
        backgroundColor: theme.palette.primary.light,
        marginBottom: '16px',
        marginTop: '16px'
    },
    searchControl: {
        width: '100%',
        paddingRight: '8px',
        paddingLeft: '16px',
        marginBottom: '16px',
        marginTop: '16px'
    },
    startAdornment: {
        fontSize: '1rem',
        color: theme.palette.grey[500]
    },
    flex: {
        display: 'flex'
    },
    name: {
        marginLeft: '2px',
        fontWeight: 400
    },
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 250px)',
        overflowX: 'hidden'
    },
    badgeWarning: {
        backgroundColor: theme.palette.warning.dark,
        color: '#fff'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    feedbackBtn: {
        backgroundColor: theme.palette.primary.dark,
        '&:hover': {
            // backgroundColor: theme.palette.grey[300]
            // opacity: 0.9
        }
    }
}));

//-----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = (props, { ...others }) => {
    const classes = useStyles();
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();

    const [sdm, setSdm] = React.useState(true);
    const [value, setValue] = React.useState('');
    const [notification, setNotification] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const [userMenuOpen, setUserMenuOpen] = React.useState(false);
    const [feedbackDialogOpen, setFeedbackDialogOpen] = React.useState(false);

    const scriptedRef = useScriptRef();
    const [checked, setChecked] = React.useState(true);

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [userData, setUserData] = useState({})

    const fetchUserData = () =>
        axiosHttp.get('/user').then(response => {
            return response.data
        })

    useEffect(() => {
        fetchUserData().then((data) => {
            setUserData(data)
        })
    }, []);


    const anchorRef = React.useRef(null);
    const handleLogout = () => {
        axiosHttp
            .post('/logout')
            .then(function (response) {
                if (response.data.success) {
                    dispatcher({ type: LOGOUT });
                } else {
                    console.log(response.data.msg);
                }
            })
            .catch(function (error) {
                dispatcher({ type: LOGOUT });
                console.log(error.response.data.msg);
            });
    };

    const handleManagePlan = () => {
        axiosHttp
            .post('/customer-portal')
            .then(function (response) {
                if (response.data.success) {
                    window.location.replace(response.data.url)
                } else {
                    console.log(response.data.msg);
                }
            })
            .catch(function (error) {
                console.log(error.response.data.msg);
            });
    };

    const handleUserMenuToggle = () => {
        setUserMenuOpen((prevOpen) => !prevOpen);
    };
    const handleFeedbackDialogToggle = () => {
        setFeedbackDialogOpen((prevOpen) => !prevOpen);
    };
    const handleUserMenuClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setUserMenuOpen(false);
    };
    const handleFeedbackDialogClose = (event) => {
        // if (anchorRef.current && anchorRef.current.contains(event.target)) {
        //     return;
        // }

        setFeedbackDialogOpen(false);
    };


    const prevOpen = React.useRef(userMenuOpen);
    React.useEffect(() => {
        if (prevOpen.current === true && userMenuOpen === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = userMenuOpen;
    }, [userMenuOpen]);
    return (
        <React.Fragment>
            <Chip component={Link} to='/upgrade'
                classes={{ label: classes.profileLabel }}
                className={classes.profileChip}
                label={<IconArrowBigUpLines stroke={1.5} size="1.5rem" color={theme.palette.primary.dark} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={userMenuOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="secondary"
                clickable
                sx={{ display: (userData.plan != "pro") ? '' : 'none' }}
            />
            <Chip
                classes={{ label: classes.profileLabel }}
                className={classes.profileChip}
                label={<IconMessageHeart stroke={1.5} size="1.5rem" color={theme.palette.primary.dark} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={feedbackDialogOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleFeedbackDialogToggle}
                color="primary"
            />
            <Chip
                classes={{ label: classes.profileLabel }}
                className={classes.profileChip}
                label={<IconHelp stroke={1.5} size="1.5rem" color={theme.palette.primary.dark} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={userMenuOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={() => { window.open(config.supportUrl, '_blank') }}
                color="primary"
                clickable
            />
            <Chip
                classes={{ label: classes.profileLabel }}
                className={classes.profileChip}
                label={<IconUser stroke={1.5} size="1.5rem" color={theme.palette.primary.dark} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={userMenuOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleUserMenuToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={userMenuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={userMenuOpen} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleUserMenuClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{ backgroundColor: theme.palette.primary.light }}>
                                    <CardContent className={classes.cardContent}>
                                        <Grid container direction="column" spacing={0}>
                                            {/* <Grid item className={classes.flex}>
                                                <Typography variant="h4">Hello,</Typography>
                                                <Typography component="span" variant="h4" className={classes.name}>
                                                    John Doe
                                                </Typography>
                                            </Grid> */}
                                            {/* <Grid item>
                                                <Typography variant="subtitle2">Project Admin</Typography>
                                            </Grid> */}
                                            <Grid>
                                                <List component="nav" className={classes.navContainer}>
                                                    <ListItemText>
                                                        <Typography variant="subtitle1" className={classes.listItem}>
                                                            {userData.email}
                                                        </Typography>
                                                    </ListItemText>
                                                    <ListItemButton component={Link} to='/affiliate'
                                                        className={classes.listItem}
                                                        sx={{
                                                            borderRadius: customization.borderRadius + 'px',
                                                            display: !userData.affiliate ? 'none' : ''
                                                        }}
                                                        selected={selectedIndex === 4}
                                                    >
                                                        <ListItemIcon>
                                                            <IconUsers stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography>Affiliate Portal</Typography>} />
                                                    </ListItemButton>
                                                    {/* <ListItemButton component={Link} to='/settings'
                                                        className={classes.listItem}
                                                        sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                        selected={selectedIndex === 4}
                                                    >
                                                        <ListItemIcon>
                                                            <IconSettings stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography>Settings</Typography>} />
                                                    </ListItemButton> */}
                                                    <ListItemButton
                                                        className={classes.ListItemButton}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleManagePlan}
                                                        sx={{
                                                            borderRadius: customization.borderRadius + 'px',
                                                            display: ((!userData.plan || userData.plan === 'free') ? 'none' : '')
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <IconDashboard stroke={1.5} size="1.3rem" className={classes.Icon} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography>Plan</Typography>} />
                                                    </ListItemButton>
                                                    <ListItemButton
                                                        className={classes.ListItemButton}
                                                        sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleLogout}
                                                    >
                                                        <ListItemIcon >
                                                            <IconLogout stroke={1.5} size="1.3rem" className={classes.Icon} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography>Logout</Typography>} />
                                                    </ListItemButton>
                                                </List>
                                            </Grid>
                                        </Grid>
                                        {/* <OutlinedInput
                                            className={classes.searchControl}
                                            id="input-search-profile"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            placeholder="Search profile options"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconSearch stroke={1.5} size="1.3rem" className={classes.startAdornment} />
                                                </InputAdornment>
                                            }
                                            aria-describedby="search-helper-text"
                                            inputProps={{
                                                'aria-label': 'weight'
                                            }}
                                        />
                                        <Divider />
                                        <PerfectScrollbar className={classes.ScrollHeight}>
                                            <UpgradePlanCard />
                                            <Divider />
                                            <Card className={classes.card}>
                                                <CardContent>
                                                    <Grid container spacing={3} direction="column">
                                                        <Grid item>
                                                            <Grid item container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">Start DND Mode</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        color="primary"
                                                                        checked={sdm}
                                                                        onChange={(e) => setSdm(e.target.checked)}
                                                                        name="sdm"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid item container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">Allow Notifications</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        checked={notification}
                                                                        onChange={(e) => setNotification(e.target.checked)}
                                                                        name="sdm"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                            <Divider />
                                            <List component="nav" className={classes.navContainer}>
                                                <ListItemButton
                                                    className={classes.listItem}
                                                    sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </PerfectScrollbar> */}
                                    </CardContent>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            <Dialog
                open={feedbackDialogOpen}
                onClose={handleFeedbackDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: theme.palette.primary.light }}>
                    <Typography
                        variant='h3'
                    // mt={2}
                    // sx={{
                    //     position: 'absolute',
                    //     left: 8,
                    //     top: 8,
                    //     color: (theme) => theme.palette.primary.dark,
                    // }}
                    >Feedback</Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleFeedbackDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.secondary.dark,
                    }}
                >
                    <IconX />
                </IconButton>
                <DialogContent sx={{ backgroundColor: theme.palette.primary.light }}>
                    <Formik
                        initialValues={{
                            subject: '',
                            msg: '',
                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            subject: Yup.string().required('Subject is required'),
                            msg: Yup.string().max(2048).required('Message is required')
                        })}
                        onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                axiosHttp
                                    .post('/feedback', {
                                        subject: values.subject,
                                        msg: values.msg
                                    })
                                    .then(function (response) {
                                        if (response.data.success) {
                                            console.log(response.data);
                                            handleFeedbackDialogClose()
                                            alert(response.data.msg)
                                            if (scriptedRef.current) {
                                                setStatus({ success: true });
                                                setSubmitting(false);
                                            }
                                        } else {
                                            setStatus({ success: false });
                                            setErrors({ submit: response.data.msg });
                                            setSubmitting(false);
                                        }
                                    })
                                    .catch(function (error) {
                                        console.log(error)
                                        setStatus({ success: false });
                                        setErrors({ submit: error.response.data.msg });
                                        setSubmitting(false);
                                    });
                            } catch (err) {
                                console.error(err);
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ errors, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit} {...others}>
                                <Box>
                                    <FormControl fullWidth error={Boolean(touched.subject && errors.subject)}>
                                        <InputLabel id="demo-simple-select-helper-label">Subject</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={values.subject}
                                            name={'subject'}
                                            label="Subject"
                                            onChange={handleChange}
                                            inputProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        sx: {
                                                            backgroundColor: theme.palette.primary.light
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            <MenuItem value={'feature'}>Feature</MenuItem>
                                            <MenuItem value={'bug'}>Bug</MenuItem>
                                            <MenuItem value={'other'}>Other</MenuItem>
                                        </Select>
                                        {touched.subject && errors.subject && (
                                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                                {' '}
                                                {errors.subject}{' '}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>

                                <Box mt={3}>
                                    <FormControl fullWidth error={Boolean(touched.msg && errors.msg)} className={classes.loginInput}>
                                        <InputLabel htmlFor="outlined-adornment-password-login">Message</InputLabel>
                                        <OutlinedInput
                                            multiline={true}
                                            rows={3}
                                            id="outlined-adornment-password-login"
                                            value={values.msg}
                                            name="msg"
                                            onChange={handleChange}
                                            label="Message"
                                            inputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                        />
                                        {touched.msg && errors.msg && (
                                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                                {' '}
                                                {errors.msg}{' '}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>
                                {errors.submit && (
                                    <Box
                                        sx={{
                                            mt: 3
                                        }}
                                    >
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}

                                <Box
                                    sx={{
                                        mt: 2
                                    }}
                                >
                                    <AnimateButton>
                                        <Button
                                            // clickable
                                            // className={classes.feedbackBtn}
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Submit
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>


        </React.Fragment>
    );
};

export default ProfileSection;
