import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Router, Routes, Route, Navigate, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import Loadable from '../ui-component/Loadable';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';

// project imports
import config from './../config';
import OtherRoutes from './OtherRoutes';

import RouteGuard from '../utils/route-guard/RouteGuard';
import MainLayout from './../layout/MainLayout';

import { TestPrepLoader } from './Loaders'

import ProtectedRoute from '../utils/route-guard/AuthGuard2';
import MinimalLayout from '../layout/MinimalLayout';
import AuthGuard from '../utils/route-guard/AuthGuard';
import GuestGuard from '../utils/route-guard/GuestGuard';

const AuthLogin = Loadable(lazy(() => import('../views/pages/authentication/login')));
const AuthRegister = Loadable(lazy(() => import('../views/pages/authentication/register')));

const ForgotPassword = Loadable(lazy(() => import('../views/pages/authentication/forgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../views/pages/authentication/resetPassword')));

const Home = Loadable(lazy(() => import('../views/main/Home')));
const Create = Loadable(lazy(() => import('../views/main/Create')));
const Set = Loadable(lazy(() => import('../views/main/Set')));
const Play = Loadable(lazy(() => import('../views/main/Play')));
const Practice = Loadable(lazy(() => import('../views/main/Practice')));
const Settings = Loadable(lazy(() => import('../views/main/Settings')));
const Upgrade = Loadable(lazy(() => import('../views/main/Upgrade')));
const Page404 = Loadable(lazy(() => import('../views/errors/404')));

const TestPrep = Loadable(lazy(() => import('../views/main/TestPrep')))

const Affiliate = Loadable(lazy(() => import('../views/main/Affiliate')))



//-----------------------|| ROUTING RENDER ||-----------------------//


const Test = () => {
    return <h1>hello world</h1>
}

const AuthLoader = () => {
    const account = useSelector((state) => state.account);
    const { isLoggedIn } = account;

    if (!isLoggedIn) {
        return redirect('/login')
    }
}

export const router = createBrowserRouter([
    {
        id: "main",
        path: "/",
        // errorElement: (<Navigate to='/' />),
        // loader: AuthLoader,
        element: (<AuthGuard><MainLayout /></AuthGuard>),
        children: [
            {
                index: true,
                element: (<Home />)
            },
            {
                path: 'create',
                element: (<Create />)
            },
            {
                path: 'set/:id',
                element: (<Set />)
            },
            {
                path: 'set/:id/play',
                element: (<Play />)
            },
            {
                path: 'set/:id/practice',
                element: (<Practice />)
            },
            {
                path: 'upgrade',
                element: (<Upgrade />)
            },
            {
                path: 'test-prep/*',
                element: (<TestPrep/>)
            },
            {
                path: 'affiliate',
                element: (<Affiliate/>)
            }
        ]
    },
    {
        id: 'auth',
        path: '/',
        element: (<GuestGuard><MinimalLayout /></GuestGuard>),
        children: [
            {
                path: 'login',
                element: (<AuthLogin />)
            },
            {
                path: 'register',
                element: (<AuthRegister />)
            }
        ]
    },
    {
        id: 'both',
        path: '/',
        element: (<MinimalLayout />),
        children: [
            {
                path: 'forgot-password',
                element: (<ForgotPassword />)
            },
            {
                path: 'reset-password/:token',
                element: (<ResetPassword />)
            }
        ]
    }
]);


const rrs = () => {
    return (
        <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />        // <Router>
        //     <RouteGuard
        //         Guest={
        //             <Routes>
        //                 <Route path="/login" element={<AuthLogin />} />
        //                 <Route path="/register" element={<AuthRegister />} />

        //                 <Route path="*" element={<Navigate to="/login" />} />
        //             </Routes>
        //         }
        //         Private={
        //             <MainLayout>
        //                 <Routes>
        //                     <Route path="/" element={<Navigate to={config.defaultPath} state={{ from: '/' }} />} />
        //                     <Route path="/home" element={<Home />} />
        //                     <Route path="/upgrade" element={<Upgrade />} />

        //                     <Route path="/create" element={<Create />} />
        //                     <Route path="/set/:id" element={<Set />} />
        //                     <Route path="/set/:id/play" element={<Play />} />
        //                     <Route path="/set/:id/practice" element={<Practice />} />

        //                     {/* loader: check for url params and redirect accordingly */}
        //                     <Route path="test-prep/*" loader={TestPrepLoader} element={<TestPrep />} />

        //                     {/* <Route path="*" element={<Navigate to="/home" />} /> */}
        //                 </Routes>
        //             </MainLayout>
        //         }
        //         Both={
        //             <Routes>
        //                 <Route path="/forgot-password" element={<ForgotPassword />} />
        //                 <Route path="/reset-password/:token" element={<ResetPassword />} />
        //             </Routes>
        //         }
        //     />
        // </Router>
    );
};

export default rrs;
